import React, { Component } from "react";
import "./App.css";
import { fire, getFireDB } from "./Firebase";

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      memo: []
    };
    fire();
  }

  componentDidMount() {
    getFireDB().then(res => {
      this.setState({
        memo: res.val().memos
      });
    });
  }

  render() {
    const { memo } = this.state;
    return (
      <div className="App">
        <h1>Schedule</h1>
        <ul>
          {memo.map((one, i) => {
            return (
              <li key={i}>
                {" "}
                {one.title} {one.desc}{" "}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
