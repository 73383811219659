import * as firebase from "firebase";
let database;
let config = {
  apiKey: "AIzaSyCff7i4FITYeQ5JKAyQmOKwASO7JRlp4VY",
  authDomain: "my-firebase-ffa97.firebaseapp.com",
  databaseURL: "https://my-firebase-ffa97.firebaseio.com",
  projectId: "my-firebase-ffa97",
  storageBucket: "my-firebase-ffa97.appspot.com",
  messagingSenderId: "30580801933"
};

export const fire = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
  database = firebase.database();
};

export const getFireDB = () => {
  return database.ref("/").once("value");
};
